import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import PrivacySection from '../components/PrivacySection/PrivacySection';
import Seo from '../components/seo';

const PrivacyPage = ({ data, location }) => {
  const companyName = data.site.siteMetadata.companyName;
  return (
    <Layout>
      <PrivacySection companyName={companyName} />
    </Layout>
  );
};

export default PrivacyPage;

export const Head = () => (
  <Seo
    title="プライバシーポリシー"
    description="補助金助成金ドットコムのプライバシーポリシーはこちら"
  />
);

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        companyName
      }
    }
  }
`;
